import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/_work/designsystem-docs/designsystem-docs/src/templates/MDXPage.jsx";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const CheckList = makeShortcode("CheckList");
const CheckListItem = makeShortcode("CheckListItem");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Generelt`}</h2>
    <p>{`Vi mennesker har et stort mangfold av ferdigheter, behov og ønsker. Behovene våre endrer seg også gjerne ut ifra situasjonen vi er i, omgivelsene rundt, dagsformen, alderen vår – og mye, mye mer.`}</p>
    <p>{`Kundene våre bruker løsningene på mange forskjellige måter og med mange ulike hjelpemidler. Det er viktig at vi tenker på det når vi designer og utvikler produktene og tjenestene.`}</p>
    <p>{`Noen har blant annet nedsatt syn, motorikk og bevegelighet, hørsel og kognisjon. Bufdir anslår at `}<a parentName="p" {...{
        "href": "https://bufdir.no/Statistikk_og_analyse/Nedsatt_funksjonsevne/Antall/"
      }}>{`mellom 15% og 18% av befolkningen har det som defineres som nedsatt funksjonsevne`}</a>{`, og Norges Blindeforbund anslår at `}<a parentName="p" {...{
        "href": "https://www.blindeforbundet.no/oyehelse-og-synshemninger/fakta-og-statistikk-om-synshemninger"
      }}>{`mer enn 320 000 personer lever med synshemninger i Norge`}</a>{`. De fleste av oss vil i løpet av livet oppleve en midlertidig funksjonsnedsettelse, for eksempel et brukket ben eller en annen skade som påvirker behovene våre.`}</p>
    <p>{`Mange bruker hjelpemidler eller tilrettelagt programvare. Løsningene våre vil for eksempel brukes med både skjermlesere, tastaturnavigering, talegjenkjenning, skjermforstørring og forskjellige input-enheter.`}</p>
    <p>{`Universell utforming handler om å lage løsningene på en måte som gjør at så mange som mulig kan bruke og ha glede av dem.`}</p>
    <h2>{`Bruk tydelig språk`}</h2>
    <p>{`Vi leser og prosesserer tekst ulikt. Tydelig språk er viktig for at alle skal kunne forstå informasjonen.`}</p>
    <h3>{`Bruk informativ og beskrivende tekst i knapper og lenker`}</h3>
    <p>{`Knapper og lenker bør ha tekst som gjør det enkelt å skjønne hvilken handling man utfører, eller hvor lenken peker.`}</p>
    <div className="ffe-grid ffe-grid--inline sb1ds-dos-donts">
    <div className="ffe-grid__row">
        <div className="sb1ds-good ffe-grid__col--sm-12 ffe-grid__col--md-6">
            <div className="sb1ds-do-dont">
                <h4>Gjør</h4>
                <CheckList mdxType="CheckList">
                    <CheckListItem mdxType="CheckListItem">Informativ og beskrivende tekst i knapper</CheckListItem>
                </CheckList>
                <div className="sb1ds-do-dont__example">
                    <button className="ffe-button ffe-button--primary">
                        <span className="ffe-button__label">Gå videre til betaling</span>
                    </button>
                </div>
            </div>
        </div>
        <div className="sb1ds-bad ffe-grid__col--sm-12 ffe-grid__col--md-6">
            <div className="sb1ds-do-dont">
                <h4>Unngå</h4>
                 <CheckList mdxType="CheckList">
                    <CheckListItem isCross={true} mdxType="CheckListItem">Generisk, ukonkret tekst</CheckListItem>
                </CheckList>
                <div className="sb1ds-do-dont__example">
                    <button className="ffe-button ffe-button--primary">
                        <span className="ffe-button__label">Klikk her</span>
                    </button>
                </div>
            </div>
        </div>
    </div>
    <div className="ffe-grid__row">
        <div className="sb1ds-good ffe-grid__col--sm-12 ffe-grid__col--md-6">
            <div className="sb1ds-do-dont">
                <h4>Gjør</h4>
                 <CheckList mdxType="CheckList">
                    <CheckListItem mdxType="CheckListItem">Informativ og beskrivende tekst i lenker</CheckListItem>
                </CheckList>
                <div className="sb1ds-do-dont__example">
                    <a href="#">Hvilket fond er best for meg?</a>
                </div>
            </div>
        </div>
        <div className="sb1ds-bad ffe-grid__col--sm-12 ffe-grid__col--md-6">
            <div className="sb1ds-do-dont">
                <h4>Unngå</h4>
                 <CheckList mdxType="CheckList">
                    <CheckListItem isCross={true} mdxType="CheckListItem">Generisk, ukonkret tekst</CheckListItem>
                </CheckList>
                <div className="sb1ds-do-dont__example">
                    <a href="#">Les mer</a>
                </div>
            </div>
        </div>
    </div>
    </div>
    <h3>{`Bruk informative og beskrivende overskrifter`}</h3>
    <p>{`Mange brukere navigerer fra overskrift til overskrift med skjermlesere og tastaturnavigering.`}</p>
    <ul>
      <li parentName="ul">{`Overskrifter bør beskrive innholdet de representerer`}</li>
      <li parentName="ul">{`Vær obs på overskriftsnivå – `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`<h1>`}</code>{` er sidetittel`}</li>
    </ul>
    <h3>{`Ta semantisk HTML til hjelp`}</h3>
    <p>{`Semantisk HTML tilbyr ekstra informasjon som kan gjøre innholdet lettere å forstå.`}</p>
    <div className="sb1ds-do-dont">
    <h4>Eksempel</h4>
    <p className="sb1ds-do-dont__description ffe-small-text"><code>&lt;abbr&gt;</code> brukes for å beskrive forkortelser.</p>
    <div className="sb1ds-do-dont__example">
        <code>&lt;abbr title=&quot;November&quot;&gt;Nov&lt;/abbr&gt;</code>
    </div>
    </div>
    <h3>{`Tilby alternativer for ikke-tekstlig innhold`}</h3>
    <p>{`Noen brukere kan ikke se bilder eller høre lyd. Derfor er det viktig å tilby alternativer til innhold som ikke er ren tekst.`}</p>
    <ul>
      <li parentName="ul">{`Videoer bør ha undertekst`}</li>
      <li parentName="ul">{`Bilder bør ha en beskrivende og informativ alt-tekst`}</li>
      <li parentName="ul">{`Unngå tekst i bilder - det blir ikke lest opp av skjermlesere`}</li>
    </ul>
    <h2>{`Vær visuelt tydelig`}</h2>
    <p>{`Løsningene bør være enkle å forstå, uten behov for forkunnskaper eller forklaring.`}</p>
    <h3>{`Bruk gjerne kjente mønstre og konvensjoner`}</h3>
    <p>{`Fokus på brukeropplevelse går hånd i hånd med universell utforming. Når vi bruker etablerte mønstre og konvensjoner som kundene kjenner igjen kan løsningene være intuitive selv om de er komplekse.`}</p>
    <h3>{`Sørg for god kontrast`}</h3>
    <p>{`Teksten må ha tilstrekkelig kontrast mot bakgrunnen for å gi god nok lesbarhet. WCAG 2.0 (nivå AA) krever at kontrastforholdet er minst på 4.5:1 for stor tekst og 3.0:1 for liten tekst. Det finnes mange verktøy for å sjekke kontrasten, for eksempel `}<a parentName="p" {...{
        "href": "https://webaim.org/resources/contrastchecker/"
      }}>{`WebAIM Contrast Checker`}</a>{`.`}</p>
    <p>{`Husk også på kontrastforholdene når du designer og utvikler for dark mode.`}</p>
    <h3>{`Sett maks 80 tegn som tekstbredde`}</h3>
    <p>{`Det blir både lettere og mer effektivt å lese en tekst når tekstbredden er innenfor 80 tegn (inkludert mellomrom). Hvis tekstbredden går over dette, kan noen oppleve at det blir lettere å dette ut av teksten og tyngre å lese teksten.`}</p>
    <h3>{`Pass på at klikkflaten er stor nok`}</h3>
    <p>{`Klikkbare elementer bør være lette å treffe, blant annet for brukere med motoriske utfordringer og brukere som benytter seg av musemulering eller andre input-hjelpemidler.`}</p>
    <ul>
      <li parentName="ul">{`Klikkbare elementer bør ha tilstrekkelig størrelse, ikke minst på mobilskjermer`}</li>
      <li parentName="ul">{`Avstanden mellom klikkbare elementer bør være stor nok til at faren for å klikke feil reduseres`}</li>
      <li parentName="ul">{`Radioknapper og sjekkbokser bør være tilknyttet et klikkbart `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`<label>`}</code></li>
    </ul>
    <h3>{`Gi visuell feedback`}</h3>
    <p>{`Visuell feedback hjelper brukerne med å forstå hvordan brukergrensesnittet reagerer på handlingene deres.`}</p>
    <ul>
      <li parentName="ul"><code parentName="li" {...{
          "className": "language-text"
        }}>{`:hover`}</code></li>
      <li parentName="ul"><code parentName="li" {...{
          "className": "language-text"
        }}>{`:active`}</code></li>
      <li parentName="ul"><code parentName="li" {...{
          "className": "language-text"
        }}>{`:focus`}</code></li>
    </ul>
    <h3>{`Ta høyde for skjermforstørring`}</h3>
    <p>{`Skjermforstørrere er et hjelpemiddel for personer som er svaksynte, som forstørrer skjermbildet i forskjellig grad. Løsningene våre bør kunne forstørres uten at det hindrer bruk.`}</p>
    <ul>
      <li parentName="ul">{`Vær bevisst på hvordan whitespace påvirker opplevelsen i forstørret tilstand`}</li>
      <li parentName="ul">{`Vær obs på avstanden mellom elementer som henger sammen, for eksempel `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`<dt>`}</code>{` og `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`<dd>`}</code>{` i description list`}</li>
    </ul>
    <h3>{`Husk at noen forstørrer teksten`}</h3>
    <p>{`Brukere skal kunne forstørre tekst inntil 200%, uten at det endrer grensesnittet på en sånn måte at det hindrer bruk.`}</p>
    <ul>
      <li parentName="ul">{`Vær obs på overflow`}</li>
      <li parentName="ul">{`Unngå hardkodet høyde på elementer der forstørret innhold tar opp mer plass enn containeren`}</li>
    </ul>
    <div className="sb1ds-do-dont">
    <h4>Eksempel</h4>
    <p className="sb1ds-do-dont__description ffe-small-text">Hardkodet høyde kan by på problemer ved tekstforstørring, spesielt i kombinasjon med <code>overflow: hidden</code>.</p>
    <div className="sb1ds-do-dont__example">
        <div className="ffe-card-base ffe-text-card" style={{
          maxWidth: '300px',
          fontSize: '125%',
          marginNottom: 0
        }}>
          <p className="ffe-card-component ffe-card-component--card-name">Kortnavn</p>
          <p className="ffe-card-component ffe-card-component--title ffe-card-component--title--overflow-ellipsis">Tittel</p>
          <span className="ffe-card-component ffe-card-component--subtext">Subtext er grå</span>
          <p className="ffe-card-component ffe-card-component--text" style={{
            height: '115px'
          }}>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
        </div>
    </div>
    </div>
    <h2>{`Skriv semantisk HTML`}</h2>
    <p>{`Semantisk HTML hjelper både mennesker og maskiner med å forstå strukturen i websider.`}</p>
    <h3>{`Bruk tagger som beskriver innholdet`}</h3>
    <p>{`De aller fleste HTML-tagger har en semantisk betydning som beskriver dataene de inneholder. Det gjør det lettere å tolke både strukturen og innholdet for skjermlesere og andre hjelpemidler.`}</p>
    <h4>{`Eksempler`}</h4>
    <ul>
      <li parentName="ul"><code parentName="li" {...{
          "className": "language-text"
        }}>{`<nav>`}</code>{` - Navigasjon`}</li>
      <li parentName="ul"><code parentName="li" {...{
          "className": "language-text"
        }}>{`<address>`}</code>{` - Kontaktinformasjon`}</li>
      <li parentName="ul"><code parentName="li" {...{
          "className": "language-text"
        }}>{`<main>`}</code>{` - Hovedinnhold på en side`}</li>
    </ul>
    <p>{`MDN har en `}<a parentName="p" {...{
        "href": "https://developer.mozilla.org/en-US/docs/Web/HTML/Element"
      }}>{`fullstendig liste over HTML-elementer og hvordan de brukes`}</a>{`.`}</p>
    <h3>{`Bruk ikke-semantiske elementer kun til layout, eller beskriv de med ARIA-attributter`}</h3>
    <p><code parentName="p" {...{
        "className": "language-text"
      }}>{`<div>`}</code>{` og `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`<span>`}</code>{` er HTML-elementer som ikke har noen semantisk betydning. Derfor egner de seg til bruk som containere for andre HTML-elementer, men de kan også brukes som innholdscontainere i kombinasjon med ARIA-attributter som beskriver innholdet.`}</p>
    <h3>{`Bruk ARIA-attributter`}</h3>
    <p>{`WAI-ARIA tilbyr mange attributter som kan brukes for å gjøre innholdet mer tilgjengelig. For eksempel:`}</p>
    <ul>
      <li parentName="ul">{`Widgeter som ikke har tilsvarende HTML-elementer`}
        <ul parentName="li">
          <li parentName="ul"><code parentName="li" {...{
              "className": "language-text"
            }}>{`<div role=”tablist”>`}</code></li>
        </ul>
      </li>
      <li parentName="ul">{`Tilstand/state`}
        <ul parentName="li">
          <li parentName="ul"><code parentName="li" {...{
              "className": "language-text"
            }}>{`aria-checked`}</code></li>
          <li parentName="ul"><code parentName="li" {...{
              "className": "language-text"
            }}>{`aria-disabled`}</code></li>
          <li parentName="ul"><code parentName="li" {...{
              "className": "language-text"
            }}>{`aria-expanded`}</code></li>
        </ul>
      </li>
      <li parentName="ul">{`Skjema`}
        <ul parentName="li">
          <li parentName="ul"><code parentName="li" {...{
              "className": "language-text"
            }}>{`aria-required`}</code></li>
          <li parentName="ul"><code parentName="li" {...{
              "className": "language-text"
            }}>{`aria-errormessage`}</code></li>
        </ul>
      </li>
    </ul>
    <h4>{`Bruk semantiske HTML-tagger i stedet, om mulig`}</h4>
    <p>{`En del ARIA-attributter har samme betydning som semantiske HTML-elementer. I de tilfellene bør du velge HTML-elementene. For eksempel:`}</p>
    <ul>
      <li parentName="ul"><code parentName="li" {...{
          "className": "language-text"
        }}>{`<main>`}</code>{` fremfor `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`<div role="main">`}</code></li>
      <li parentName="ul"><code parentName="li" {...{
          "className": "language-text"
        }}>{`<button>`}</code>{` fremfor `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`<input role="button">`}</code></li>
    </ul>
    <p><a parentName="p" {...{
        "href": "https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA"
      }}>{`ARIA er grundig dokumentert hos MDN`}</a>{`.`}</p>
    <h3>{`Vær obs på rekkefølgen på overskrifter`}</h3>
    <ul>
      <li parentName="ul"><code parentName="li" {...{
          "className": "language-text"
        }}>{`<h1>`}</code>{` er sidetittel – bruk bare én `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`<h1>`}</code>{` på hver side`}</li>
      <li parentName="ul"><code parentName="li" {...{
          "className": "language-text"
        }}>{`<h2>`}</code>{` - `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`<h6>`}</code>{` er de resterende nivåene`}</li>
      <li parentName="ul">{`Unngå å hoppe over nivåer`}</li>
    </ul>
    <div className="ffe-grid ffe-grid--inline sb1ds-dos-donts">
    <div className="ffe-grid__row">
        <div className="sb1ds-good ffe-grid__col--sm-12 ffe-grid__col--md-6">
            <div className="sb1ds-do-dont">
                <h4>Gjør</h4>
                 <CheckList mdxType="CheckList">
                    <CheckListItem mdxType="CheckListItem">Ranger overskrifter i riktig rekkefølge</CheckListItem>
                </CheckList>
                <div className="sb1ds-do-dont__example">
                    <pre><code>
&lt;h1&gt;Sidetittel&lt;/h1&gt;<br />
&nbsp;&nbsp;&lt;h2&gt;Niv&aring; 2&lt;/h2&gt;<br />
&nbsp;&nbsp;&nbsp;&nbsp;&lt;h3&gt;Niv&aring; 3&lt;/h3&gt;<br />
&nbsp;&nbsp;&lt;h2&gt;Niv&aring; 2&lt;/h2&gt;<br />
&nbsp;&nbsp;&nbsp;&nbsp;&lt;h3&gt;Niv&aring; 3&lt;/h3&gt;<br />
&nbsp;&nbsp;&nbsp;&nbsp;&lt;h3&gt;Niv&aring; 3&lt;/h3&gt;<br />
&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;h4&gt;Niv&aring; 4&lt;/h4&gt;<br />
                    </code></pre>
                </div>
            </div>
        </div>
        <div className="sb1ds-bad ffe-grid__col--sm-12 ffe-grid__col--md-6">
            <div className="sb1ds-do-dont">
                <h4>Unngå</h4>
                 <CheckList mdxType="CheckList">
                    <CheckListItem isCross={true} mdxType="CheckListItem">Unngå å hoppe over overskriftsnivåer</CheckListItem>
                </CheckList>
                <div className="sb1ds-do-dont__example">
                    <pre><code>
&lt;h1&gt;Sidetittel&lt;/h1&gt;<br />
&nbsp;&nbsp;&lt;h3&gt;Niv&aring; 3&lt;/h3&gt;<br />
&nbsp;&nbsp;&lt;h3&gt;Niv&aring; 3&lt;/h3&gt;<br />
&nbsp;&nbsp;&nbsp;&nbsp;&lt;h5&gt;Niv&aring; 5&lt;/h5&gt;<br />
&nbsp;&nbsp;&nbsp;&nbsp;&lt;h5&gt;Niv&aring; 5&lt;/h5&gt;<br />
&nbsp;&nbsp;&lt;h3&gt;Niv&aring; 3&lt;/h3&gt;<br />
&nbsp;&nbsp;&nbsp;&nbsp;&lt;h6&gt;Niv&aring; 6&lt;/h6&gt;<br />
                    </code></pre>
                </div>
            </div>
        </div>
    </div>
    </div>
    <h3>{`Lenker eller knapper?`}</h3>
    <ul>
      <li parentName="ul">{`Knapper utfører en funksjon`}</li>
      <li parentName="ul">{`Lenker tar deg videre til en annen side`}</li>
    </ul>
    <h3>{`Bilder`}</h3>
    <ul>
      <li parentName="ul">{`Bruk en beskrivende og informativ alt-tekst`}</li>
      <li parentName="ul">{`Bilder som brukes til ren dekorasjon kan ha tomt alt-attributtt`}</li>
      <li parentName="ul">{`Unngå tekst i bilder`}</li>
    </ul>
    <h2>{`Nyttige verktøy`}</h2>
    <p>{`Browser-plugins, validering og andre automatiske verktøy kan gi verdifull informasjon underveis i utviklingen.`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.deque.com/axe/"
        }}>{`Axe`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://wave.webaim.org/"
        }}>{`Wave`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://accessibilityinsights.io/"
        }}>{`Accessibility insights`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://snook.ca/technical/colour_contrast/colour.html#fg=33FF33,bg=333333"
        }}>{`Colour Contrast Check`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.funkify.org/"
        }}>{`Funkify`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://validator.w3.org/"
        }}>{`W3C Markup Validation`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.w3.org/WAI/ER/tools/"
        }}>{`WAI valideringsverktøy`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://webaim.org/resources/contrastchecker/"
        }}>{`Kontrastsjekk`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://wave.webaim.org/"
        }}>{`WAVE Accessibility Evaluation Tool`}</a></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      